.scene-container.next {
  display: none !important;
}

.scene-container {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.vidContCell {
  position: relative;
  overflow: hidden;
}

.scene {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.hotspotDiv {
  height: 18px;
  width: 18px;
  cursor: pointer;
  position: absolute;
}

.hotspotDiv img {
  height: 100%;
  width: 100%;
}

#back-button {
  height: 75px;
  width: 100px;
  top: 5%;
  position: relative;
  left: 2%;
  cursor: pointer;
}

#highthroughput {
  height: 18%;
  width: 10%;
  position: absolute;
  cursor: pointer;
  left: 87.5%;
  top: 5vh;
}

#highthroughput img {
  height: 100%;
  width: 100%;
}

.menu-options {
  position: absolute;
  width: 7vw;
  top: 5vh;
  background-color: white;
  -moz-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
}

.menu-btn {
  height: 12vh;
  position: relative;
  width: 100%;
  cursor: pointer;
  padding: 14%;
}

.menu-icon {
  width: 100%;
}

#show-resources {
  width: 79%;
}

.gray-out-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000000;
  display: none;
  opacity: 0;
  z-index: 99;
}

.fade-in {
  display: block;
  opacity: 0.3;
  transition: opacity 2s;
}

#show-faq {
  width: 36%;
 }
 

 .external-button {
  height: 7.5em;
  width: 7.5em;
  background-color: #ef8100;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 7.5em;
  padding: 1em;
  margin: 1em;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out
}

.external-button:hover {
  color: #fff;
  background: #ff8a00;
  box-shadow: 2px 2px 4px #b0b0b0;
}

.external-button-text {
  font-weight: 700;
  font-size: 0.75em;
  color: white;
}

.external-links {
  position: absolute;
  left: 75.5%;
  top: 5vh;
}


@media only screen and (max-width: 813px) {
  .external-button {
    height: 4.5em !important;
    width: 4.5em !important;
    border-radius: 4.5em !important;
    padding: 0em !important;
    margin: 0.25em !important;
  }
  .external-button-text {
    font-size: 0.25em !important;
  }
  #rollover-instructions {
    height: 4em !important;
  }
}

@media only screen and (max-width: 1000px) {
  .menu-btn {
    height: 12vh;
    position: relative;
    width: 100%;
    cursor: pointer;
    padding: 3%;
  }
}
