#result-content {
  display: flex;
  height: 100vh;
  width: 100vw;
}

#result-menu-overlay {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
}

.description {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
  color: white;
}

.description-container {
  margin-left: 6vw;
  margin-top: 10vh;
  width: 80%;
}

.description-title {
  font-weight: bold;
  font-size: 3vh;
}

.description-subtitle {
  font-weight: normal;
  font-size: 2.5vh;
  padding-bottom: 0.5rem;
}

.description-bullets {
  font-weight: normal;
  font-size: 2.5vh;
  line-height: 5vh;
}

.spec-container {
  margin-left: 2vw;
  margin-top: 9vh;
  width: 80%;
}

.spec-title {
  font-weight: bold;
  font-size: 2.5vh;
}

.spec-subtitle {
  font-weight: normal;
  font-size: 2vh;
  padding-bottom: 0.5rem;
}

.spec-bullets {
  font-weight: normal;
  font-size: 2.5vh;
  line-height: 4.15vh;
}

.result-menu-btn {
  cursor: pointer;
}

#overlay-details {
  width: 50%;
  height: 100%;
  right: 0px;
  top: 0px;
  position: absolute;
  background-color: rgba(82, 90, 96, 0.6);
  float: right;
  overflow: hidden;
  display: none;
}

.logo-results {
  position: absolute;
  bottom: 30px;
  right: 125px;
  height: 7rem;
  width: 7rem;
}

.video-wrapper {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}
