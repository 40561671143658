.question-text {
  font-size: 18px;
  font-weight: bold;
}

.answer-wrapper {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.faq-arrow {
  width: 15px;
  height: 26px;
}

.arrow-wrapper {
  width: 15px;
  height: 26px;
  margin-right: 0.5rem;
}

.answer-text {
  margin-left: 0.5em;
  visibility: hidden;
  height: 0;
  width: 100%;
}

.answer-text-nowrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 17px;
  padding-left: 20px;
  transition: all 1s ease;
}

.white-space-normal {
  white-space: normal !important;
  transition: all 1s ease;
  height: 8em !important;
  overflow-y: scroll;
  visibility: visible;
}

#question-btn {
  height: 11%;
  display: none !important;
}

.custom-btn {
  background-color: #00a1df;
  border-color: #00a1df;
  width: 75%;
}

#faq-content {
  max-height: 64vh;
  overflow-y: scroll;
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: #525a60;
}

.btn-primary:hover {
  background-color: #00a1df;
  border-color: #00a1df;
}

#contact-us {
  width: 30%;
  height: 100%;
  right: 0px;
  top: 0px;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  display: none;
  z-index: 999;
}

.fieldWrapper input {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 3.5rem;
  border-color: #b0b0b0;
}

.fieldWrapper textarea {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-color: #b0b0b0;
}
